import {
  interpolateString,
  loadGoogleMaps,
  requestUserLocation,
  userHasLocationEnabled,
} from 'SCRIPT_UTILS/index';
import {
  AddressComponent,
  AddressType,
  GeocodingResponseStatus,
  GeocodingResult,
} from '@google/maps';

const excludedRegionCountries = ['United States', 'Canada'];
const geolocationTimeout = 10000;
const outOfRegionOpenClass = 'jsa-cmp-out-of-region--open';

const replaceInterpolatedText = (countryLongName: string, el: Element): void => {
  if (!el.textContent || !(el instanceof HTMLElement)) {
    return;
  }

  const token = el.dataset.interpolateToken;

  if (!token) {
    return;
  }

  el.textContent = interpolateString(el.textContent, { [token]: countryLongName });
};

const inExcludedRegion = (countryLongName: string): boolean => {
  return excludedRegionCountries.includes(countryLongName);
};

const parseGeolocationForCountry = (
  geocoderOkStatus: string,
  results: any,
  status: any
): any | GeocodingResult[] => {
  if (status !== geocoderOkStatus) {
    console.error(`Geocoder failed: ${status}`);
    return;
  }

  if (!results || !results[0] || !results[0].address_components) {
    console.log('No geolocation results found.');
    return;
  }

  /* eslint-disable  @typescript-eslint/naming-convention */
  const country = results[0]?.address_components?.find((address_component: { types: string | string[]; }) => {
    return address_component?.types && address_component?.types?.includes('country');
  });

  if (!country || !country.long_name) {
    console.log('No country found.');
    return;
  }

  return country;
};

// This is a candidate for abstraction to the utilities/googleMaps in the future.
const geocodeAddress = async (
  geocoder: google.maps.Geocoder,
  latLng: google.maps.LatLng,
  geocoderOkStatus: string
) => {
  return await new Promise<AddressComponent<AddressType> | undefined>((resolve) => {
    geocoder.geocode({ location: latLng }, (results, status) => {
      return resolve(parseGeolocationForCountry(geocoderOkStatus, results, status));
    });
  }).catch(() => {
    // Do nothing. This allows us to avoid unnecessary try / catches below and instead return early after async await.
  });
};

const notifyIfOutOfRegion = async () => {
  const outOfRegionEl: HTMLElement | null = document.querySelector('.js-out-of-region');

  if (!outOfRegionEl) {
    console.error('No out of region element found.');
    return;
  }

  const clientId: string | undefined = outOfRegionEl.dataset.googleMapsClientId;

  if (!clientId) {
    console.error('No Google Maps client ID provided');
    return;
  }

  const coords: any = await requestUserLocation(geolocationTimeout);

  if (!coords) {
    console.error('Could not access users position.');
    return;
  }

  const googleMaps = await loadGoogleMaps(clientId);

  if (!googleMaps) {
    console.error('Could not load Google Maps.');
    return;
  }

  const geocoder = new googleMaps.Geocoder();
  const latLng = new googleMaps.LatLng(coords.latitude, coords.longitude);
  const country = await geocodeAddress(geocoder, latLng, googleMaps.GeocoderStatus.OK);

  if (!country) {
    console.error('Could not find a country.');
    return;
  }

  const isInExcludedRegion = inExcludedRegion(country.long_name);

  if (isInExcludedRegion) {
    return;
  }

  const interpolateTextEls = outOfRegionEl.querySelectorAll('.js-interpolate-text');
  interpolateTextEls.forEach(replaceInterpolatedText.bind(undefined, country.long_name));
  const preselectCountryCodeEls = document.querySelectorAll(`[data-preselect-country-code]`);

  preselectCountryCodeEls.forEach((el) => {
    el.setAttribute('data-preselect-country-code', country.short_name);
  });

  outOfRegionEl.classList.add(outOfRegionOpenClass);
};

const initiateRegionDetection = () => {
  if (userHasLocationEnabled()) {
    notifyIfOutOfRegion();
  }
};

document.addEventListener('DOMContentLoaded', initiateRegionDetection);
