import { initiateRegionSelector } from "./modal";

// Hide Title & Section in Footer
const getRegionSection = document.querySelector('.cmp-experiencefragment--footer .js-out-of-region');
if(getRegionSection) {
    (getRegionSection as HTMLElement).style.display = 'none';
}

// Fetch DATA with API

let resourcePath = (document.getElementsByClassName('js-out-of-region')[0] as HTMLElement).dataset.resourcePath
const REGIONSELECTORAPI = '/bin/api/corp/regionSelectorData.json' + resourcePath + '.json';
let regionData;
async function fetchRegionSelectorData() {
  try {
    await fetch(`${REGIONSELECTORAPI}`)
    .then(response => response.json())
    .then(data => {
      regionSelector(data);
      regionData = data;
    })
  } catch(er) {
    console.error(er)
  }
}

function regionSelector(regionSelectorData){
  // Region Selector Modal and Warning Details with API Response
  // Get Modal elements
  const modalTitle = document.getElementsByClassName('cmp-region-modal-header__title')[0]
  const modalCopyrightText = document.getElementsByClassName('cmp-region-modal__copyright')[0]
  let mapClientId = (document.getElementsByClassName('js-out-of-region')[0] as HTMLElement).dataset.googleMapsClientId
  const modalDescription = document.getElementsByClassName('cmp-region-modal-header__description')[0]
  const modalCloseButtonText = document.getElementsByClassName('cmp-region-modal-header__close-button-text')[0]

  // Get Warning elements
  const warningLinkText = document.querySelector('.cmp-out-of-region__link > span.js-interpolate-text')
  const warningDescription = document.getElementsByClassName('cmp-out-of-region__description')[0]
  const warningButtonText = document.getElementsByClassName('cmp-out-of-region__button')[0]
  const warningTitle = document.getElementsByClassName('cmp-out-of-region__title')[0]
  const warningSubtitle = document.getElementsByClassName('cmp-out-of-region__subtitle')[0]

  // Update Modal elements
  modalTitle.innerHTML = regionSelectorData.modalTitle
  modalCopyrightText.innerHTML = regionSelectorData.modalCopyrightText
  mapClientId = regionSelectorData.mapClientId
  modalDescription.innerHTML = regionSelectorData.modalDescription
  modalCloseButtonText.innerHTML = regionSelectorData.modalCloseButtonText

  // Update Warning elements
  warningLinkText.innerHTML = regionSelectorData.warningSectionLinkText
  warningDescription.innerHTML = regionSelectorData.warningSectionDescription
  warningButtonText.innerHTML = regionSelectorData.warningSectionButtonText
  warningTitle.innerHTML = regionSelectorData.warningSectionTitle
  warningSubtitle.innerHTML = regionSelectorData.warningSectionSubtitle

  const getRegionSelector = document.querySelector('.cmp-region-modal-regions');

  // Fetch Region Names
  if(regionSelectorData) {
    regionSelectorData.regions.forEach(( region )=>{
      if(getRegionSelector) {
        getRegionSelector.insertAdjacentHTML('beforeend',
          `<li class="cmp-region-modal-regions__item js-region-modal__item js-region-modal__item--region">
            <ul class="cmp-region-modal-regions__col cmp-region-modal-regions__countries-list js-region-modal-col" data-title="${region.regionName}">

            </ul>
            <button class="cmp-region-modal-regions__item-button js-region-modal__open-child-button" data-title="${region.regionName}" onclick="getRegionsData(this)">
              <span class="cmp-region-modal-regions__item-text">${region.regionName}</span>
            </button>
          </li>`
        )
      }
    })
  }
}

// As getCountriesData function called on click of Dynamic button
// it needs to be on the Global/window object else the function will be undefined.

// Fetch Regions Data on click of Region Name
(window as any).getRegionsData = (data) => {
  let countries;
  let countriesList='';
  const regionName = data.getAttribute('data-title');

  if(Array.isArray(regionData.regions) && regionData.regions.length) {
    countries = regionData.regions.find(item => item.regionName === regionName).countries;
    countries.forEach(( country ) => {
      if(country.countryIcon) {
        countriesList += `<li class="cmp-region-modal-regions__item js-region-modal__item js-region-modal__item--country">
          <button class="cmp-region-modal-regions__item-button js-region-modal__open-child-button" data-country-code="${country.countryCode}" data-title="${country.countryName}" data-countryxfpath="${country.countryXfPath}" onclick="getCountriesData(this)">
            <img class="cmp-region-modal-regions__item-icon" src="${country?.countryIcon}" alt="${country.countryName}" />
            <span class="cmp-region-modal-regions__item-text">${country.countryName}</span>
          </button>
          <div class="cmp-region-modal-regions__col cmp-region-modal-regions__country-content js-region-modal-col" data-title=" ">
          </div>
        </li>`
      } else {
        countriesList += `<li class="cmp-region-modal-regions__item js-region-modal__item js-region-modal__item--country">
        <button class="cmp-region-modal-regions__item-button js-region-modal__open-child-button" data-country-code="${country.countryCode}" data-title="${country.countryName}" data-countryxfpath="${country.countryXfPath}" onclick="getCountriesData(this)">
          <span class="cmp-region-modal-regions__item-text">${country.countryName}</span>
        </button>
        <div class="cmp-region-modal-regions__col cmp-region-modal-regions__country-content js-region-modal-col" data-title=" ">
        </div>
      </li>`
      }
    })

    if(data.parentElement.childNodes[1].childNodes.length === 1 ) {
      data.parentElement.childNodes[1].insertAdjacentHTML('beforeend', countriesList);
    }
    initiateRegionSelector();
  }
}

// Fetch Countries Data from an API
(window as any).getCountriesData = async function(xfPath) {
  if(xfPath) {
    const countriesData = document.querySelector('.cmp-region-modal-regions__country-content');
    const htmlResponseData = document.createElement('div');
    const xfPathURL = xfPath?.dataset?.countryxfpath;
    const parentPath = xfPath.parentNode.getElementsByClassName("cmp-region-modal-regions__country-content");

    if(xfPathURL) {
      try {
        const response = await fetch(`${xfPathURL}.html`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const responseData = await response.text();
        if(countriesData) {
          htmlResponseData.innerHTML = responseData;
          const getCountriesContentData = htmlResponseData.getElementsByClassName('root');
          if(getCountriesContentData.length > 0 && (getCountriesContentData[0].innerHTML.length) !== 0) {
            parentPath[0].innerHTML = getCountriesContentData[0].innerHTML;
          }
        }
      } catch(er) {
        console.error(er)
      }
    }
  }
}

fetchRegionSelectorData();
