const openChildCssClass = 'jsa-region-modal__child--open';
const regionModalColCssClass = 'js-region-modal-col';
const openColCssClass = 'jsa-region-modal-col--open';

const closeRegion = (el: HTMLElement) => {
  el.classList.remove(openChildCssClass);

  if (el.parentNode && el.parentNode instanceof HTMLElement) {
    el.parentNode.classList.remove(openColCssClass);
  }
};

const closeOtherOpenRegions = (currentTargetRegion: HTMLElement) => {
  const parentListEl = currentTargetRegion.closest(`.${regionModalColCssClass}`);

  if (!parentListEl) {
    return;
  }

  const openLists: NodeListOf<HTMLElement> = parentListEl.querySelectorAll(`.${openChildCssClass}`);
  const otherOpenLists: HTMLElement[] = Array
    .from(openLists)
    .filter(openList => openList !== currentTargetRegion.parentNode);

  otherOpenLists.forEach(closeRegion);
};

const setTitleOnChildCol = (currentTargetRegionListItem: HTMLElement, title: string) => {
  const childColEl = currentTargetRegionListItem.querySelector(`.${regionModalColCssClass}`);

  if (!childColEl) {
    return;
  }

  childColEl.setAttribute('data-title', title);
};

const toggleCurrentRegion = (currentTarget: HTMLElement) => {
  const parentEl = currentTarget.parentNode as HTMLElement;

  parentEl.classList.toggle(openChildCssClass);

  if (parentEl.parentNode && parentEl.parentNode instanceof HTMLElement) {
    parentEl.parentNode.classList.toggle(openColCssClass);
  }

  if (!currentTarget.dataset || !currentTarget.dataset.title) {
    return;
  }

  setTitleOnChildCol(parentEl, currentTarget.dataset.title);
};

const toggleChildRegion = (event: MouseEvent) => {
  const {currentTarget} = event;

  if (!currentTarget || !(currentTarget instanceof HTMLElement)) {
    return;
  }

  closeOtherOpenRegions(currentTarget);
  toggleCurrentRegion(currentTarget);
};

const removeEscListener = () => {
  document.removeEventListener('keydown', escListener);
};

const addEscListener = () => {
  document.addEventListener('keydown', escListener);
};

const escListener = (event: KeyboardEvent) => {
  if (event.key === "Escape") {
    toggleRegionModal();
  }
};

const toggleRegionModal = () => {
  const bodyEl = document.querySelector('body');
  const regionModalEl = document.querySelector('.js-region-modal');
  const regionModalOpenCssClass = 'jsa-region-modal--open';
  const regionModalOpenBodyCssClass = 'jsa-modal-open';

  if (!bodyEl || !regionModalEl) {
    return;
  }

  if (regionModalEl.classList.contains(regionModalOpenCssClass)) {
    removeEscListener();
  } else {
    addEscListener();
  }

  bodyEl.classList.toggle(regionModalOpenBodyCssClass);
  regionModalEl.classList.toggle(regionModalOpenCssClass);
};

const addToggleChildRegionClickHandler = (regionSelectorButtonEl: HTMLElement): void => {
  regionSelectorButtonEl.addEventListener('click', toggleChildRegion);
};

const addToggleModalClickHandler = (regionSelectorTriggerEl: HTMLElement): void => {
  regionSelectorTriggerEl.addEventListener('click', toggleRegionModalOnClick);
};

const preselectCountry = (countryCode: string) => {
  const countryToggleEl = document.querySelector(`[data-country-code='${countryCode}']`) as HTMLElement;

  if (!countryToggleEl) {
    return;
  }

  const parentRegionItemEl = countryToggleEl.closest('.js-region-modal__item--region');

  if (!parentRegionItemEl) {
    return;
  }

  const parentRegionItemButtonEl: HTMLElement | null =
    parentRegionItemEl.querySelector(':scope > .js-region-modal__open-child-button');

  if (!parentRegionItemButtonEl) {
    return;
  }

  // check to see if the region & country are already selected
  const hasRegionSelected = parentRegionItemEl.classList.contains(openChildCssClass);
  const hasCountrySelected =
    countryToggleEl.closest('.js-region-modal__item--country')?.classList.contains(openChildCssClass);

  // only click if the region is not already selected
  if (!hasRegionSelected) {
    parentRegionItemButtonEl.click();
  }

  // only click if the country is not already selected
  if (!hasCountrySelected) {
    countryToggleEl.click();
  }
};

const toggleRegionModalOnClick = (event: Event): void => {
  const {currentTarget} = event;

  if (currentTarget && currentTarget instanceof HTMLElement && currentTarget.dataset.preselectCountryCode) {
    preselectCountry(currentTarget.dataset.preselectCountryCode);
  }

  toggleRegionModal();
};

export const initiateRegionSelector = () => {
  const regionSelectorButtonEls: NodeListOf<HTMLButtonElement> =
    document.querySelectorAll('.js-region-modal__open-child-button');
  const regionSelectorTriggerEls: NodeListOf<HTMLElement> =
    document.querySelectorAll('.js-region-modal-toggle');

  regionSelectorTriggerEls.forEach(addToggleModalClickHandler);
  regionSelectorButtonEls.forEach(addToggleChildRegionClickHandler);
};

window.onload = () => initiateRegionSelector();
